const mixin = {
  data () {
    return {
      confirmButtonStatus: false
    }
  },
  methods: {
    handleConfirmStart () {
      this.confirmButtonStatus = true
    },
    handleConfirmEnd () {
      this.confirmButtonStatus = false
    }
  }
}

export default mixin