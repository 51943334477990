export const vindicateColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '巡检点名称',
      align: 'left',
      dataIndex: 'name',
    },
    {
      title: '类型',
      align: 'center',
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' },
    },
    {
      title: '标记',
      align: 'center',
      dataIndex: 'tag',
    },
    {
      title: '所属维护部门',
      align: 'left',
      dataIndex: 'department.name',
    },
    // {
    //   title: '所属区域',
    //   align: 'center',
    //   dataIndex: 'area.name'
    // },
    {
      title: '所在位置',
      align: 'left',
      customRender: (text, record) => {
        const time = `${record.area ? record.area.name : ''}  ${record.location ? record.location : ''}` //es6写法
        return time
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '250px',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
export const vindicateTypeSelect = function () {
  return [
    { value: 'other', label: '其他' },
    { value: 'monitoring_point', label: '监测点' },
  ]
}
export const questionTypeSelect = function () {
  return [
    { value: 'STATE', label: '状态型' },
    { value: 'NUMBER', label: '数值型' },
    { value: 'TEXT', label: '文本型' },
  ]
}
export const questionColumns = () => {
  return [
    {
      title: '序号',
      width: '60px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '巡检项目',
      align: 'left',
      width: '150px',
      dataIndex: 'name',
    },
    {
      title: '项目类型',
      align: 'center',
      width: '150px',
      scopedSlots: { customRender: 'type' },
    },
    {
      title: '默认值',
      align: 'center',
      width: '120px',
      dataIndex: 'defaultValue',
    },
    {
      title: '可选值',
      align: 'center',
      width: '400',
      scopedSlots: { customRender: 'probablyOption' },
    },
    {
      title: '最小值',
      align: 'center',
      width: '80px',
      scopedSlots: { customRender: 'minValue' },
    },
    {
      title: '最大值',
      align: 'center',
      width: '80px',
      scopedSlots: { customRender: 'maxValue' },
    },
    {
      title: '操作',
      align: 'center',
      width: 200,
      fixed: 'right',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
// 设备列表表头
export const deviceColumns = () => {
  return [
    {
      title: '序号',
      width: '50',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      ellipsis: true,
      key: 'name',
      align: 'center',
    },
    {
      title: '设备编号',
      dataIndex: 'no',
      ellipsis: true,
      key: 'no',
      align: 'center',
    },
    {
      title: '设备类型',
      dataIndex: 'category.name',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '设备所属部门',
      dataIndex: 'department.name',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '安装位置',
      dataIndex: 'detailLocation',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const facilityColumns = () => {
  return [
    {
      title: '序号',
      width: 50,
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '设施名称',
      ellipsis: true,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '设施类型',
      ellipsis: true,
      align: 'center',
      scopedSlots: { customRender: 'facilityType' },
    },
    {
      title: '所属部门',
      ellipsis: true,
      align: 'center',
      dataIndex: 'department.name',
    },
    {
      title: '所在位置',
      ellipsis: true,
      align: 'center',
      scopedSlots: { customRender: 'location' },
    },
    {
      title: '标绘类型',
      align: 'center',
      scopedSlots: { customRender: 'plotType' },
    },
    {
      title: '备注',
      ellipsis: true,
      align: 'center',
      dataIndex: 'remarks',
    },
    {
      title: '包含设备数量',
      align: 'center',
      scopedSlots: { customRender: 'deviceNumber' },
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
