<template>
  <a-spin :spinning="spinning">
    <div class="page-header-index-wide" style="overflow-x: scroll;">
      <a-input-search v-model="searchParams.name" allow-clear style="margin:10px 0" placeholder="输入节点名称" enter-button @search="search"/>
      <a-tree :key="1" v-if="treeData && treeData.length" :isLeaf="true" show-line :default-expanded-keys="[1]" :treeData="treeData"
              @select="selectEquipmentTypeTree">
        <a-icon slot="switcherIcon" type="caret-down"/>
        <template slot="custom" slot-scope="item">
          <a-row>
            <span>{{item.title}}</span>
            <span style="color: #00A0E9">{{item.num ? '(' + item.num + ')' : ''}}</span>
          </a-row>
        </template>
      </a-tree>
      <a-empty v-if="treeData.length === 0" style="height: 205px;margin-top: 81px;"/>
    </div>
  </a-spin>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'equipmentTypeTree',
    mixins: [],
    components: {},
    props: {
      type: {
        type: String
      },
      // 排除的节点id
      excludeId: {
        type: [String, Number],
        default: null
      },
      // 是否需要查询节点详情
      detail: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        treeData: [],
        spinning: true,
        id: '0',
        selectNode: null,
        treeShow: true,
        searchParams: {
          name: '',
          treeNodeId: 0,
          levelNum: 2,
          needNum: true,
          excludeId: this.excludeId
        }
      }
    },
    watch: {
      excludeId (val) {
        this.searchParams.excludeId = val
      }
    },
    computed: {},
    created () {
      this.search()
    },
    methods: {
      search () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.spot_tree,
          noTips: true,
          data: this.searchParams,
          success: (data) => {
            this.treeData = []
            this.treeData = data.body
            this.spinning = false
          }
        })
      },
      selectEquipmentTypeTree (selectedKeys, e) {
        this.$emit('selectedTreeNode', selectedKeys, e.node)
      }
    }
  }
</script>
<style scoped>
  .ant-tree-title {
    width: 100%;
  }

  .title {
    float: left;
  }
</style>