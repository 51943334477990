import modalConfirmLoading from './modal-confirm-loading'

export default {
  mixins: [modalConfirmLoading],
  data () {
    return {
      addModal: false,
      editModal: false,
      detailDrawer: false,
      addLoading: false,
      editLoading: false,
      entityBaseUrl: null,
      deleteUrl: '',
      searchUrl: '',
      delMoreUrl: '',
      exportUrl: '',
      exportWordLoading: false,
      exportWordUrl: '',
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
      },
      tableData: [],
      columns: [],
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: ''
      },
      initParams: {},
      selectedRowKeys: [],
      columnsList: [],
      tableLoading: false,
      autoStartSearch: true
    }
  },
  created () {
    if (!this.entityBaseUrl) {
      console.error('请定义\'entityBaseUrl\'')
    } else {
      this.deleteUrl = this.entityBaseUrl.delete
      this.searchUrl = this.entityBaseUrl.search
      this.delMoreUrl = this.entityBaseUrl.delMore
      this.exportWordUrl = this.entityBaseUrl.export_word
    }
    if (!this.initColumns) {
      console.error('请定义\'initColumns\'')
    }
    this.initColumns.forEach((item, index) => {
      this.columns.push(item)
    })
    this.columns.forEach((item, index) => {
      this.columnsList.push(item.title)
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      Object.assign(this.initParams, this.searchParams)
      if (this.autoStartSearch) {
        this.search()
      }
    },
    /*添加*/
    handleAdd () {
      this.addModal = true
    },
    handleAddOk () {
      // this.addLoading = true
      if (this.$refs['addRef']) {
        if (
          typeof this.$refs['addRef'].submitAdd !== 'undefined' &&
          this.$refs['addRef'].submitAdd instanceof Function
        ) {
          this.$refs['addRef'].submitAdd()
        } else {
          console.error('Form子组件内部未定义submitAdd方法')
        }
      } else {
        console.error('Form子组件的addRef未指定')
      }
    },
    closeAddModal () {
      this.addModal = false
    },
    addSuccess () {
      // this.addLoading = false
      this.confirmButtonStatus = false
      this.addModal = false
      this.search()
      // this.$message.success('创建成功！')
    },
    addError (errorType) {
      this.confirmButtonStatus = false
      //this.$message.warning('创建失败！')
      if (errorType === 'formError') {
        // 表单验证失败
      } else if (errorType === 'httpError') {
        // 网络请求失败
      }
      this.addLoading = false
      //this.addModal = false
    },
    /*修改*/
    handleEdit (id) {
      this.editModal = true
      this.$nextTick(() => {
        if (this.$refs['editRef']) {
          if (
            typeof this.$refs['editRef'].loadData !== 'undefined' &&
            this.$refs['editRef'].loadData instanceof Function
          ) {
            this.$refs['editRef'].loadData(id)
          } else {
            console.error('Form子组件内部未定义loadData方法')
          }
        } else {
          console.error('Form子组件的editRef未指定')
        }
      })
    },
    handleEditOk () {
      //this.editLoading = true
      if (this.$refs['editRef']) {
        if (
          typeof this.$refs['editRef'].submitEdit !== 'undefined' &&
          this.$refs['editRef'].submitEdit instanceof Function
        ) {
          this.$refs['editRef'].submitEdit()
        } else {
          console.error('Form子组件内部未定义submitEdit方法')
        }
      } else {
        console.error('Form子组件的editRef未指定')
      }
    },
    closeEditModal () {
      if (this.$refs['editRef']) {
        if (this.$refs['editRef'].formItem.images || this.$refs['editRef'].formItem.files) {
          this.$refs.editRef.formItem.imageIds = []
          this.$refs.editRef.formItem.images = []
          this.$refs.editRef.formItem.fileIds = []
          this.$refs.editRef.formItem.files = []
        } else {
          // console.log('该modal中没有图片、文件')
        }
      }
      this.editModal = false
    },
    editSuccess () {
      //this.editLoading = false
      this.confirmButtonStatus = false
      this.editModal = false
      if (this.$refs.editRef.formItem.images || this.$refs.editRef.formItem.files) {
        this.$refs.editRef.formItem.imageIds = []
        this.$refs.editRef.formItem.images = []
        this.$refs.editRef.formItem.fileIds = []
        this.$refs.editRef.formItem.files = []
      }
      this.loadTableData()
      // this.$message.success('修改成功！')
    },
    editError (errorType) {
      if (errorType === 'formError') {
        // 表单验证失败
      } else if (errorType === 'httpError') {
        // 网络请求失败
      }
      this.confirmButtonStatus = false
      this.editLoading = false
      // this.$message.warning('修改失败！')
      // this.editModal = false
    },
    /*删除*/
    handleDelete (id, content) {
      if (!this.deleteUrl) {
        console.error('请在api中定义\'delete\'')
        return
      }

      const _this = this
      this.$confirm({
        title: '确认删除操作?',
        content: content || '此数据将会被删除',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          return new Promise((resolve, reject) => {
            _this.$http(_this, {
              url: _this.deleteUrl,
              params: {
                id: id
              },
              loading: 'tableLoading',
              noTips: false,
              successTipsContent: '数据删除成功!',
              success: () => {
                _this.loadTableData()
                _this.deleteSucceed()
                resolve()
              }
            })
          }).catch(() => console.log('Oops delete errors!'))
        },
        onCancel () {
        }
      })
    },
    /*删除成功后执行*/
    deleteSucceed () {
    },
    /*重置*/
    resetForm () {
      Object.assign(this.searchParams, this.initParams)
      this.search()
    },
    /*查询*/
    search () {
      this.restPage()
      this.loadTableData()
    },

    loadTableData () {
      if (!this.searchUrl) {
        console.error('请在api中定义\'search\'')
      }
      this.$http(this, {
        url: this.searchUrl,
        noTips: true,
        loading: 'tableLoading',
        data: this.searchParams,
        success: (data) => {
          this.tableData = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = data.body.number + 1
          this.pagination.pageSize = data.body.size
          this.searchParams.size = data.body.size
          this.searchParams.number = data.body.number
          this.searchParams.page = data.body.number
        }
      })
    },
    restPage () {
      this.searchParams.number = 0
    },
    /*表格页数发生变化*/
    tableChange (pagination, filters, sorter) {
      // this.searchParams.size = pagination.pageSize
      // this.searchParams.number = pagination.current - 1
      this.setSearchParamsPage(pagination)
      // if (sorter.order === 'ascend') {
      //   this.searchParams.direction = 'ASC'
      // } else {
      //   this.searchParams.direction = 'DESC'
      // }
      // this.searchParams.order = sorter.field
      this.loadTableData()
    },
    setSearchParamsPage (pagination) {
      this.searchParams.page = pagination.current - 1
      this.searchParams.number = pagination.current - 1
      this.searchParams.size = pagination.pageSize
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleDeleteMore () {
      if (!this.delMoreUrl) {
        console.error('请在api中定义\'delMore\'')
      }

      const _this = this
      if (_this.selectedRowKeys.length <= 0) {
        _this.$message.warning('请选择数据')
      } else {
        this.$confirm({
          title: '确认删除操作?',
          content: '此数据将会被删除',
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          onOk () {
            _this.$http(_this, {
              url: _this.delMoreUrl,
              data: _this.selectedRowKeys,
              loading: 'tableLoading',
              success: () => {
                _this.loadTableData()
              }
            })
          },
          onCancel () {
          }
        })
      }
    },
    handleEditMore () {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择数据')
      } else {
        if (this.selectedRowKeys.length === 1) {
          this.handleEdit(this.selectedRowKeys[0])
        } else {
          this.$message.warning('请确认只选择一条数据')
        }
      }
    },
    handleColumnsList (columnsList) {
      // ↓重置表格列
      this.columns = []
      this.initColumns.forEach((item) => {
        this.columns.push(item)
      })
      // ↓判断表格列是否在选中列中
      let _columns = []
      this.columns.forEach((item, index) => {
        if (columnsList.indexOf(item.title) !== -1) {
          _columns.push(this.columns[index])
        }
      })
      // ↓将临时列重新赋值给表格列
      this.columns = []
      _columns.forEach((item) => {
        this.columns.push(item)
      })
    },
    handleReloadColumns () {
      this.columns = []
      this.initColumns.forEach((item, index) => {
        this.columns.push(item)
      })
      this.columns.forEach((item, index) => {
        this.columnsList.push(item.title)
      })
    },
    //详情抽屉
    handleView (id) {
      this.detailDrawer = true
      this.$nextTick(() => {
        if (this.$refs['viewRef']) {
          if (
            typeof this.$refs['viewRef'].loadData !== 'undefined' &&
            this.$refs['viewRef'].loadData instanceof Function
          ) {
            this.$refs['viewRef'].loadData(id)
          } else {
            console.error('Form子组件内部未定义loadData方法')
          }
        } else {
          console.error('Form子组件的editRef未指定')
        }
      })
    },
    detailClose () {
      this.detailDrawer = false
      this.loadTableData()
    },
    getOrgNameById (orgId) {
      // console.log(this)
      // return '-'
      let orgArr = this.$store.state.user.unitsAndDepts
      let name = '未知单位'
      orgArr.forEach((item) => {
        if (item.id === orgId) {
          name = item.name
        }
      })
      return name
    },
    handExportWord (fileName) {
      this.$http(this, {
        url: this.exportWordUrl,
        data: this.searchParams,
        noTips: true,
        export: true,
        loading: 'exportWordLoading',
        noLoadingBar: true,
        responseType: 'blob',
        success: (data) => {
          const blob = new Blob([data])
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName + '.doc'
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName + '.doc')
          }
        },
        error: () => {
        }
      })
    }
  }
}
